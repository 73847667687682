<template>
    <el-main>
        
        <!-- <el-row>
            <el-col :span="12" :offset="6">
                <el-input placeholder="请输入内容" v-model="input3">
                    <el-select v-model="select" slot="prepend" placeholder="请选择">
                        <el-option label="餐厅名" value="1"></el-option>
                        <el-option label="订单号" value="2"></el-option>
                        <el-option label="用户电话" value="3"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </el-col>
        </el-row> -->
        <img class="bg" src="@/assets/images/common/job/bg.jpg">
        <div class="container line">
            <el-input class="search-input" placeholder="请输入内容" v-model="search">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
            </el-input>
        </div>

        <el-card class="container">
            
            <!-- 筛选 -->
            <el-row :gutter="20">
                <el-col :span="3">
                    <el-select v-model="value" placeholder="工作经验" clearable>
                        <el-option
                            v-for="item in options"
                            size="mini"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="value" placeholder="工作地点" clearable>
                        <el-option
                            v-for="item in options"
                            size="mini"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="value" placeholder="工作地点" clearable>
                        <el-option
                            v-for="item in options"
                            size="mini"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="value" placeholder="工作地点" clearable>
                        <el-option
                            v-for="item in options"
                            size="mini"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>

            <!-- 数据 -->
            <el-row>
                <el-col class="listTitle">
                    开启新的工作（142）
                </el-col>

                <el-col class="positionItem">
                    <h3 class="positionItem-title">游戏引擎开发工程师-朝夕光年(字节跳动游戏业务)</h3>
                    <div class="positionItem-sub">
                        <el-tag><i class="el-icon-location-information"></i>杭州</el-tag>
                        <el-tag><i class="el-icon-user"></i>研发 - 算法</el-tag>
                        <el-tag>全职</el-tag>
                        <el-tag><i class="el-icon-warning-outline"></i>届秋招项目</el-tag>
                    </div>
                    <div class="postionItem-company"> 
                        <img class="postionItem-company-logo" src="@/assets/images/logo/1.jpg">
                        <div class="postionItem-company-info">
                            <el-link>美团点评</el-link>
                            <el-breadcrumb separator="|">
                                <el-breadcrumb-item><i class="el-icon-location-information"></i>广州-白云区</el-breadcrumb-item>
                                <el-breadcrumb-item>1-3年</el-breadcrumb-item>
                                <el-breadcrumb-item>本科</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <!-- <el-pagination
            background
            layout="prev, pager, next"
            :page-size="5"
            :total="1000">
        </el-pagination> -->

        <create-form 
            size='medium'
            labelWidth="auto"
            :inline="true"
            :createData="searchData"
            :createForm="searchForm"
            :rule="editRules"
            :createHandle="searchHandle">
        </create-form>
    </el-main>
</template>

<script>
import CreateForm from '@/components/Form'

let sexs=[{label:'男',value:'M'},{label:'女',value:'F'}]
let sexProps={label:'label',value:'value'}
let intersts=[{label:'羽毛球',value:'badminton'},{label:'篮球',value:'basketball'},{label:'足球',value:'football'},{label:'兵乓球',value:'pong'}]
let interstProps={label:'label',value:'value'}
let id = 0
let props = {
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level } = node;
                    setTimeout(() => {
                        const nodes = Array.from({ length: level + 1 })
                        .map(item => ({
                            value: ++id,
                            label: `选项${id}`,
                            leaf: level >= 2
                        }));
                        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                        resolve(nodes);
                    }, 1000);
                }
            }


export default {
    components: {
        CreateForm,
    },
    data() {
        return {
            searchData:{
                name:null,
                age:null,
                sex:null,
                date: '',
                interst: []
            },
            searchForm:[
                { type:'Input',label:'姓名：',prop:'name',width:'180px',placeholder:'请输入姓名'},
                { type:'Input',label:'年龄',prop:'age',width:'180px',placeholder:'请输入年龄'},
                { type:'Date',label:'日期',prop:'date',width:'180px',placeholder:'请选择日期'},
                { type:'Select',label:'性别',prop:'sex',width:'120px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别'},
                { type:'Checkbox',label:'爱好',prop:'interst',checkboxs:intersts,props:interstProps},
                { type:'Cascader',label:'级联选择',prop:'cascader',width:'120px',props:this.props,placeholder:'级联选择'},
            ],
            searchHandle:[
                {label:'查询',type:'primary',handle:()=>this.searchHandleForm()},
                {label:'重置',type:'primary',handle:()=>''}
            ],

            // 表单验证
            editRules: {
                name: [
                    // {requied: true, message: '请输入姓名', trigger: 'blur'},
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ]
            },



            options: [{
                value: '不限',
                label: '不限'
            }, {
                value: '在校生',
                label: '在校生'
            }, {
                value: '应届生',
                label: '应届生'
            }, {
                value: '1年以内',
                label: '1年以内'
            }, {
                value: '1-3年',
                label: '1-3年'
            }, {
                value: '3-5年',
                label: '3-5年'
            }, {
                value: '5年以上',
                label: '5年以上'
            }],
            value: '',
            // 搜索
            search: ''
        }
    },
    methods: {
        searchHandleForm() {
            console.log('searchHandle')
        },
    }
}
</script>

<style lang="less" scoped>
.el-main{
    width: 100%;
    padding: 0;
    .bg{
        width: 100%;
    }
}
.container.line{
    margin-top: 0;
}

.search-input{
    width: 100%;
    max-width: 800px;
    margin: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    .el-button{
        background-color: #409eff;
        color: #FFF;
        border-radius: 0;
        height: 50px;
    }
}
.search-input .el-input__inner{
    width: 90px !important;
}
// list
.listTitle{
    font-size: 2rem;
    color: #1f2329;
    font-weight: 700;
    margin: 30px 0 60px;
}
.positionItem{
    .positionItem-title{
        color: #1f2329;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .positionItem-sub{
        margin: 10px 0;
        color: #646a73;
    }
}
.postionItem-company{
    .postionItem-company-logo{
        width: 40px;
        height: 40px;
        float: left;
    }
    .postionItem-company-info{
        height: 20px;
        float: left;
        margin-left: 20px;
    }
    .el-breadcrumb{
        height: 20px;
        font-size: 0.8rem;
        line-height: 20px;
    }
}
</style>